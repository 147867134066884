import Bredcrumb from '../../HomeMain/Bredcrumb'
import React from 'react'
import Gallery6 from '../../HomeMain/Gallery6'

const Main = () => {
  return (
    <>
      
      <div className="gallery-block">
        <Gallery6/>
      </div>
    </>
  )
}

export default Main